<template>
  <div class="forgot-password">
    <div class="form-box">
      <h3>{{ $t('forgotpassword.title') }}</h3>
      <div>
        <form id="sign-in-form" @submit.prevent="onSubmit">
          <b-field>
            <b-input
              :placeholder="$t('forgotpassword.email')"
              v-model="email"
              type="email"
              icon="email"
              required="true"
              rules="required|max:256"
            />
          </b-field>
          <div v-if="displayMessage" class="field text-blue">
            {{ displayMessage }}
          </div>
          <div v-if="!displayMessage" class="field">
            <input type="submit" class="button is-primary is-pulled-right" value="Submit" />
          </div>
          <div v-else class="field">
            <span>
              <router-link class="href-overflow-wrap is-text is-right" to="/signin">
                {{ `Return to Sign In` }}
              </router-link>
            </span>
            <span>
              <input
                type="button"
                @click.prevent="onSubmit()"
                class="button is-primary is-pulled-right"
                value="Resend"
              />
            </span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { KMS_BACKEND } from '@/config'
import axios from 'axios'
import { notifyError, notifyMessage } from '@/services/notify'

export default {
  data: () => ({
    isDebug: true,
    email: '',
    existingUser: null,
    displayMessage: null
  }),

  metaInfo: {
    title: 'Forgot Password'
  },

  watch: {

  },

  computed: {
  },

  mounted() {

  },

  methods: {
  async onSubmit() {
      const { email } = this

      if(!email || email == undefined)
      {
        notifyError(`There was a problem processing your forgotten password.`)
        return
      }

      const payload = {
        Email: email,
      }

      var errMessage = ''
      try {
        if(this.isDebug == true) console.debug('onSubmit payload=' + JSON.stringify(payload))

        const path = `${KMS_BACKEND}/User/ForgotPasswordEmail`
        const result = await axios.post(path, payload).catch((e) => {
          console.debug('400 error for password reset=' + e.response.data.message)
          errMessage = e.response && e.response.data !== undefined && e.response.data.message !== undefined && e.response.data && e.response.data.message ? e.response.data.message : 'There was a problem resetting your password.'
          })

        if(this.isDebug==true) console.debug('result=' + JSON.stringify(result))

        if (result && result.status && result.status == 200) {
          this.displayMessage = 'Please check your email for a Reset Password Link.'
          notifyMessage('Please check your email for a Reset Password Link.')
        }
        else
        {
          notifyError(errMessage)
          this.displayMessage = 'There was a problem processing your forgotten password.'
        }
      } catch(e)
      {
        notifyError(errMessage)
        console.debug(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.forgot-password {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
