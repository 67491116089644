<template>
  <transition :name="name">
    <slot />
  </transition>
</template>

<script>
/*
  import SlideTransition from '@/components/transitions/Slide'

  <SlideTransition :name="transitionName">


  </SlideTransition>

  // 'slide-up' or 'slide-down'
  // happen if that is what is passed to the component
  // on a route change
  watch: {
    '$route': function (to, from) {
      const toIndex = _get (to, [ "params", "index" ], 1);
      const fromIndex = _get (from, [ "params", "index" ], 1);

      this.transitionName = toIndex < fromIndex ? 'slide-up' : 'slide-down'
    }
  },
*/

export default {
  props: {
    name: String
  }
}
</script>

<style lang="scss" scoped>
.slide-up-enter-active,
.slide-down-enter-active {
  transition: transform 0.4s ease-out, opacity 0.9s ease-out;
}

.slide-up-leave-active,
.slide-down-leave-active {
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}

.slide-up-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slide-down-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
