<template>
  <div class="reset-password">
    <div class="form-box">
      <h3>{{ $t('resetpassword.title') }}</h3>
      <div v-if="status && status != undefined && status.length > 0">
        {{ status }}
      </div>
      <div v-else>
        <form id="sign-in-form" @submit.prevent="onSubmit">
          <b-field>
            <b-input
              :placeholder="$t('resetpassword.password')"
              v-model="password"
              type="password"
              icon="lock"
              required="true"
              rules="required|max:8000"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('resetpassword.confirm')"
              v-model="confirm"
              type="password"
              icon="lock"
              required="true"
              rules="required|max:8000"
            />
          </b-field>
          <div class="field">
            <input type="submit" class="button is-primary is-pulled-right" value="Submit" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { KMS_BACKEND } from '@/config'
import axios from 'axios'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export default {
  data: () => ({
    isDebug: true,
    password: '',
    confirm: '',
    token: '',
    userId: '',
    existingUser: null,
    status: ''
  }),

  metaInfo: {
    title: 'Forgot Password'
  },

  watch: {

  },

  computed: {
  },

  mounted() {
    this.token = this.$route.query.t
    this.userId = this.$route.query.u

    if(!this.token || this.token == undefined || !this.userId || this.userId == undefined)
    {
      this.status = 'Invalid Reset Password Parameters'
    }
  },

  methods: {
  async onSubmit() {
      const { password, confirm } = this

      if(!password || password == undefined || !this.token || this.token == undefined || !this.userId || this.userId == undefined)
      {
        notifyError(`There was a problem processing your password reset.`)
        return
      }

      if(password != confirm)
      {
        notifyError("The new password and confirmation must match before proceeding.")
        return
      }

      const payload = {
        UserID: this.userId,
        ResetPasswordToken: this.token,
        NewPassword: password
      }

      var errMessage = ''
      try {
        if(this.isDebug == true) console.debug('onSubmit payload=' + JSON.stringify(payload))

        const path = `${KMS_BACKEND}/User/ResetPassword`

        const result = await axios.post(path, payload).catch((e) => {
          console.debug('400 error for password reset=' + e.response.data.message)
          errMessage = e.response && e.response.data !== undefined && e.response.data.message !== undefined && e.response.data && e.response.data.message ? e.response.data.message : 'There was a problem resetting your password.'
          })

        if(this.isDebug==true) console.debug('result=' + JSON.stringify(result))

        if (result && result.status && result.status == 200) {
          notifyMessage('Your password was successfully reset.')
          this.$router.push('/signin')
        }
        else
        {
          notifyError(errMessage)
        }
      } catch(e)
      {
        notifyError(errMessage)
        console.debug(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.reset-password {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
