<template>
  <div :style="{ display: 'flex' }" links-menu>
    <ul class="details-menu" role="navigation" v-if="breakpoint === 'desktop'" :style="styles_.ul">
      <li v-for="(tab, index) in tabs" :key="tab.name">
        <router-link
          class="h7"
          :to="{
            name: `${prefix}${tab.name}`,
            hash: '#tab',
            params: Object.assign(
              {
                index
              },
              params
            )
          }"
        >
          {{ tab.label }}
        </router-link>
      </li>
    </ul>

    <div :style="{ flexGrow: 1 }">
      <b-field v-if="['tablet', 'mobile'].includes(breakpoint)">
        <b-select
          rounded
          :style="{ maxWidth: '300px', width: '100%', margin: '0 auto' }"
          v-model="selectedTab"
        >
          <option v-for="(key, index) in tabs" :key="index">
            {{ key.label }}
          </option>
        </b-select>
      </b-field>
      <slot />
    </div>
  </div>
</template>

<script>
import { windowStore } from '@/utilities/window/store'
import _get from 'lodash/get'
import _merge from 'lodash/merge'

export default {
  props: {
    tabs: Array,
    prefix: {
      type: String,
      default: ''
    },
    to: Object,
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    styles: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      breakpoint: '',
      selectedTab: this.getTabName(),
      styles_: _merge(
        {},
        {
          ul: {
            width: '240px'
          }
        },
        this.styles
      )
    }
  },
  watch: {
    selectedTab() {
      const tab = this.tabs.find(tab => {
        return tab.label === this.selectedTab
      })

      const tabName = _get(tab, 'name', null)

      if (typeof tabName === 'string') {
        this.$router.push({
          name: this.prefix + tabName,
          hash: '#tab',
          params: {
            id: this.$route.params.id,
            ownerID: this.ownerID
          }
        })
      }
    }
  },
  methods: {
    getTabName() {
      let selectedTab = ''

      try {
        const tabName = this.$route.name.split('.')[1]
        const tab = this.tabs.find(tab => {
          return tab.name === tabName
        })

        if (tab !== undefined && tab) {
          selectedTab = tab.label
        }
      } catch (x) {
        console.error(x)
      }

      return selectedTab
    }
  },
  mounted() {
    this.unsubWindowStore = windowStore.subscribe((mutation, state) => {
      const mType = mutation.type

      if (mType === 'breakPointChange') {
        this.breakpoint = state.breakpoint
      }
    })

    this.breakpoint = windowStore.state.breakpoint
  },
  beforeDestroy() {
    this.unsubWindowStore()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

.details-menu {
  @media screen and (min-width: $tablet) {
    a {
      font-size: 16px !important;
    }
  }

  a {
    color: #838383;
    display: inline-block;
    padding: 0.5em 0;
    font-size: 18px;
    width: 100%;

    &:hover,
    &:active {
      color: inherit;
    }

    &.router-link-active {
      color: #ff7300; // #381;

      &::after {
        visibility: visible;
      }
    }

    &::after {
      visibility: hidden;
      content: '→';
    }
  }
}
</style>
