import { render, staticRenderFns } from "./NewRegistration.vue?vue&type=template&id=7bdef3ec&scoped=true&"
import script from "./NewRegistration.vue?vue&type=script&lang=js&"
export * from "./NewRegistration.vue?vue&type=script&lang=js&"
import style0 from "./NewRegistration.vue?vue&type=style&index=0&id=7bdef3ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bdef3ec",
  null
  
)

export default component.exports