import moment from 'moment'

export const data = () => ({
  myHtml: "<span id='supportTab'>Support Access</span>",
  toggle: false,
  loading: true,
  isDebug: false,
  profile: null,
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  },
  styles: {
    pageContentSection: {
      width: '100%'
    },
    mainContainer: {
      display: 'flex'
    },
    content: {
      margin: '0 auto',
      width: 'calc(100% - 160px)',
      overflowX: 'auto'
    },
    menu: {
      width: '260px',
      paddingLeft: '20px'
    }
  },
  tabs: [
    {
      label: 'Profile',
      name: 'profile'
    },
    {
      label: 'Account Security',
      name: 'accountSecurity'
    },
    {
      label: 'Notification Subscriptions',
      name: 'alertsubscriptions'
    },
    {
      label: 'Additional Authentication',
      name: 'vendor-twofactorauthentication'
    },
    {
      label: 'Service Types',
      name: 'vendor-servicetypes'
    }
  ],
  transitionName: 'slide-up'
})
