var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ display: 'flex' }),attrs:{"links-menu":""}},[(_vm.breakpoint === 'desktop')?_c('ul',{staticClass:"details-menu",style:(_vm.styles_.ul),attrs:{"role":"navigation"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.name},[_c('router-link',{staticClass:"h7",attrs:{"to":{
          name: ("" + _vm.prefix + (tab.name)),
          hash: '#tab',
          params: Object.assign(
            {
              index: index
            },
            _vm.params
          )
        }}},[_vm._v(" "+_vm._s(tab.label)+" ")])],1)}),0):_vm._e(),_c('div',{style:({ flexGrow: 1 })},[(['tablet', 'mobile'].includes(_vm.breakpoint))?_c('b-field',[_c('b-select',{style:({ maxWidth: '300px', width: '100%', margin: '0 auto' }),attrs:{"rounded":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(key,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(key.label)+" ")])}),0)],1):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }