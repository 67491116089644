<template>
  <div class="new-registration">
    <div class="form-box">
      <h3>{{ $t('registration.title') }}</h3>
      <h5
        v-if="
          invitedUser && invitedUser != undefined && invitedUser.hoa && invitedUser.hoa != undefined
        "
      >
        {{ invitedUser.hoa.name }}
      </h5>
      <div v-if="invitedUser && invitedUser != undefined">
        <form id="sign-in-form" @submit.prevent="onSubmit">
          <b-field>
            <b-input
              :placeholder="$t('registration.email')"
              v-model="email"
              type="email"
              icon="email"
              required="true"
              disabled="true"
              rules="required|max:256"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('registration.firstname')"
              v-model="firstName"
              type="text"
              required="true"
              rules="required|max:250"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('registration.lastname')"
              v-model="lastName"
              type="text"
              required="true"
              rules="required|max:250"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('registration.phone')"
              v-model="phone"
              type="phone"
              rules="max:8000"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('registration.password')"
              v-model="password"
              type="password"
              icon="lock"
              required="true"
              rules="required|max:8000"
            />
          </b-field>
          <b-field>
            <b-input
              :placeholder="$t('registration.confirm')"
              v-model="confirm"
              type="password"
              icon="lock"
              required="true"
              rules="required|max:8000"
            />
          </b-field>
          <div class="field">
            <input type="submit" class="button is-primary is-pulled-right" value="Registration" />
          </div>
        </form>
      </div>
      <div v-else-if="status && status != undefined && status.length > 0">
        {{ status }}
      </div>
      <div v-else>
        This invitation has expired
      </div>
    </div>
  </div>
</template>

<script type="es6">
import { mapActions } from 'vuex';
import { KMS_BACKEND } from '@/config'
import axios from 'axios'
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

export default {
  data: () => ({
    isDebug: true,
    email: '',
    name: '',
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
    confirm: '',
    invitedUser: null,
    status: '',
    groupIds: [],
    roleIds: []
  }),

  metaInfo: {
    title: 'New Registration'
  },

  watch: {

  },

  computed: {
    ...mapActions('user', ['logout'])
  },

  mounted() {
    if (this.isDebug == true) console.debug('id' + this.$route.params.id)
    this.loadInvitedUserByCode(this.$route.params.id)
    if(this.isDebug==true) console.debug('mounted invitation user=' + JSON.stringify(this.invitedUser))

    this.$store.dispatch('user/logout')
  },

  methods: {
  async loadInvitedUserByCode(code)
  {
    try {
      if(this.isDebug == true) console.debug('Retrieving New Invitation User')
      const path = `${KMS_BACKEND}/AccountSettings/InvitationUser/GetByCode?code=${code}`
      if(this.isDebug == true) console.debug('path=' + path)
      const result = await axios.get(path)

      if (result.data) {
        this.invitedUser = result.data

        this.email = this.invitedUser.emailAddress

        if(this.isDebug == true) console.debug('this.invitedUser=' + JSON.stringify(this.invitedUser))
      }
    } catch(e)
    {
      this.invitedUser = null
    }
  },
  async onSubmit() {
      const { email, password, confirm, phone, firstName, lastName } = this

      if(!this.invitedUser ||
      this.invitedUser==undefined ||
      !email ||
      email == undefined ||
      !password ||
      password==undefined ||
      !firstName ||
      firstName == undefined ||
      !lastName ||
      lastName == undefined )
      {
        notifyError(`There was a problem processing your new registration.`)
        return
      }

      if(password != confirm)
      {
        notifyError("The password and confirmation must match before proceeding.")
        return
      }

      if(this.invitedUser.emailAddress != email)
      {
        notifyError("There is a problem with the supplied email address.")
        return
      }

      this.roleIds = []
      this.groupIds = []

      //Parse out role Ids and group Ids
      if(this.invitedUser &&
      this.invitedUser != undefined)
      {
        if(this.invitedUser.invitationUserRoles && this.invitedUser.invitationUserRoles != undefined)
        {
          Array.from(this.invitedUser.invitationUserRoles).forEach(element => {
            if (element) {
              if (element.roleID && element.roleID != undefined) {
                if (element.roleID > 0) {
                  if (this.isDebug == true) console.debug('roleID=' + element.roleID)
                  this.roleIds.push(element.roleID)
                }
              }
            }
          })
        }

      if(this.invitedUser.invitationUserGroupings && this.invitedUser.invitationUserGroupings != undefined)
        {
          Array.from(this.invitedUser.invitationUserGroupings).forEach(element => {
            if (element) {
              if (element.groupingID && element.groupingID != undefined) {
                if (element.groupingID > 0) {
                  if (this.isDebug == true) console.debug('groupingID=' + element.groupingID)
                  this.groupIds.push(element.groupingID)
                }
              }
            }
          })
        }
      }

      const payload = {
        EmailAddress: email,
        HoaID: this.invitedUser.hoaID,
        Code: this.invitedUser.code,
        FirstName: firstName,
        LastName: lastName,
        Password: password,
        PhoneNumber: phone,
        IsOwner: this.invitedUser.isOwner,
        RoleIds: this.roleIds,
        GroupIds: this.groupIds
      }

      try {
        if(this.isDebug == true) console.debug('onSubmit payload=' + JSON.stringify(payload))

        const path = `${KMS_BACKEND}/AccountSettings/InvitationUser/NewRegistrationFromInvitedUser`
        const result = await axios.post(path, payload)

        if(this.isDebug==true) console.debug('result=' + JSON.stringify(result.data))

        if (result && result.data && result.data == true) {
          this.status = 'Your registration was successfully created.'
          notifyMessage(this.status)
          this.$router.push('/signin?email=' + email)
        }
        else
        {
          notifyError('There was a problem creating your new registration.')
        }
      } catch(e)
      {
        //Custom error parsing for the detailed error message
        if(e.response && e.response != undefined && e.response.data && e.response.data[0] != undefined)
        {
          if(this.isDebug == true) console.debug(JSON.stringify(e.response.data))
          const { description } = e.response.data[0]

          if(description)
          {
            notifyError(description)
          }
          else
          {
            notifyError(e)
          }
        }
        else
        {
          notifyError(e)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-box {
  max-width: 480px;
}

.new-registration {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
